.App {
    font-family: sans-serif;
    text-align: center;
    background-image: linear-gradient(to bottom right, #001E3C, #035598);
    /* background-image: linear-gradient(to bottom, #14183A, #13294B); */
  }
  
  #babylon-canvas {
    width: 90%;
    height: 100%;
  }

  a {
    color: #FFF;
  }
  a:hover {
     color: rgb(168, 168, 235)
  }
/* #my-canvas {
    width: 100%;
    height: 100%;
} */
  